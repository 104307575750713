// → contact row that can be reused as row section on a page

import React from "react";
import styled from "styled-components";
import { mainBlue, screen } from "../../components/common/variables";
import LabeledArrow from "../../components/labeled-arrow";

const Wrapper = styled.div`
  color: ${mainBlue};

  .enquiry-heading {
    font-size: 1.5rem;
    font-weight: 800;
    @media ${screen.xsmall} {
      font-size: 2.4rem;
    }
    @media ${screen.xlarge} {
      font-size: 2.5rem;
    }
  }

  .enquiry-subheading {
    font-size: 1.8rem;
    font-weight: 300;
    max-width: 650px;
    line-height: 1.25;
    margin-top: 5px;
    margin-bottom: 35px;
    @media ${screen.xsmall} {
      font-size: 2rem;
      max-width: 400px;
    }
    @media ${screen.small} {
      font-size: 2.55rem;
      max-width: 650px;
    }
    @media ${screen.medium} {
      font-size: 2.9rem;
    }
    @media ${screen.xlarge} {
      font-size: 3.1rem;
    }
  }
`;

// → heading prop = bold heading text
// → subheading prop = message under heading
// → linkLabel prop = url to use for a tag link
// link prop = if undefined just go to /contact page
const GetInTouch = ({ heading, subheading, linkLabel, link }) => {
  return (
    <Wrapper>
      <h5 className="enquiry-heading">{heading ? heading : "Heading"}</h5>
      <p className="enquiry-subheading">
        {subheading ? subheading : "Subheading"}
      </p>
      <LabeledArrow
        label={linkLabel ? linkLabel : "LINK LABEL"}
        url={link ? link : `/contact`}
      />
    </Wrapper>
  );
};

export default GetInTouch;
