import slugify from "slugify";

// → function helpers

// → return a single array of arrays
export const flatten = arr => {
  return arr.reduce(function(flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
};

// → return vimeo id number
export const getVimeoId = url => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};

// → description word reducer for excerpt
export const reduceWords = (str, length = 31) => {
  return (
    str
      .split(" ")
      .slice(0, length)
      .join(" ") + "..."
  );
};

export const toSlug = string => {
  return slugify(string, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  });
};
