import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { mainBlue, lightGrey, screen } from "../components/common/variables";
import { getVimeoId } from "../components/helpers";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import GetInTouch from "../components/get-in-touch";

const Wrapper = styled.div`
  padding-bottom: 110px;
  position: relative;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 170px;
  }
  @media ${screen.small} {
    padding-top: 76px;
  }
  @media ${screen.large} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
  }

  &::before {
    content: "";
    background-color: ${lightGrey};
    display: block;
    position: absolute;
    height: 543px;
    max-width: 670px;
    width: 100%;
    display: none;
    @media ${screen.xsmall} {
      display: block;
      max-width: 540px;
      height: 543px;
    }
    @media ${screen.small} {
      max-width: 516px;
      height: 475px;
    }
    @media ${screen.medium} {
      max-width: 670px;
      height: 581px;
    }
    @media ${screen.xlarge} {
      height: 590px;
      max-width: 830px;
    }
  }

  .inner-wrapper {
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 70px;
      position: relative;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding-bottom: 110px;
      }
      @media ${screen.small} {
        flex-direction: row;
      }
      @media ${screen.xlarge} {
        padding-bottom: 140px;
      }

      &__video {
        width: 100%;
        @media ${screen.small} {
          max-width: 560px;
        }
        @media ${screen.medium} {
          max-width: 660px;
        }

        .video-wrapper {
          position: relative;
          padding-top: 56.25%;
          &__iframe {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .video-label {
          color: ${mainBlue};
          margin-top: 10px;
          margin-left: 35px;
          @media ${screen.xsmall} {
            margin-left: 70px;
            margin-top: 16px;
          }
          @media ${screen.small} {
            margin-left: 0;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            @media ${screen.xsmall} {
              font-size: 1.4rem;
            }

            strong {
              font-weight: 700;
            }
          }
        }
      }

      &__text {
        padding-left: 35px;
        padding-right: 35px;
        @media ${screen.xsmall} {
          padding-left: 70px;
          padding-right: 70px;
          margin-top: 40px;
        }
        @media ${screen.small} {
          padding-left: 0;
          margin-left: 60px;
          margin-top: 0;
          max-width: 616px;
        }

        .page-title {
          line-height: 1.35;
          margin-top: 30px;
          @media ${screen.small} {
            max-width: 380px;
          }
          @media ${screen.medium} {
            margin-top: 50px;
          }
        }

        .service-intro {
          color: ${mainBlue};
          font-size: 1.17rem;
          font-weight: 300;
          margin-top: 20px;
          @media ${screen.xsmall} {
            font-size: 1.4rem;
          }
          @media ${screen.xlarge} {
            font-size: 1.5rem;
            margin-top: 25px;
          }
        }

        .service-description {
          color: ${mainBlue};
          margin-top: 30px;
          @media ${screen.xlarge} {
            margin-top: 50px;
          }
          p {
            font-size: 1.08rem;
            margin-bottom: 18px;
            @media ${screen.xsmall} {
              font-size: 1.08rem;
              margin-bottom: 22px;
            }
            @media ${screen.xlarge} {
              font-size: 1.25rem;
              margin-bottom: 30px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .training {
      padding-left: 35px;
      padding-right: 35px;
      @media ${screen.xsmall} {
        padding-left: 70px;
        padding-right: 70px;
      }
      @media ${screen.small} {
        padding-left: 0;
        padding-right: 0;
      }

      &__heading {
        color: ${mainBlue};
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.3;
        @media ${screen.xsmall} {
          font-size: 2.6rem;
        }
        @media ${screen.xlarge} {
          font-size: 2.8rem;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        @media ${screen.xsmall} {
          margin-top: 35px;
          margin-left: -65px;
          margin-right: -65px;
        }
        @media ${screen.small} {
          margin-left: -50px;
          margin-right: -50px;
        }
        @media ${screen.medium} {
          margin-left: -50px;
          margin-right: -50px;
          width: 90%;
        }
        @media ${screen.xlarge} {
          margin-top: 50px;
        }

        .training-item {
          width: 100%;
          margin: 30px 0;
          @media ${screen.xsmall} {
            width: calc(50% - 130px);
            margin: 0 65px 40px 65px;
          }
          @media ${screen.small} {
            width: calc(33.33% - 100px);
            margin: 0 50px 40px 50px;
          }
          @media ${screen.medium} {
            width: calc(33.33% - 90px);
            margin: 0 45px 40px 45px;
          }

          &__logo {
            display: block;
          }
        }
      }
    }

    .contact {
      margin-top: 100px;
      padding-left: 35px;
      padding-right: 35px;
      @media ${screen.xsmall} {
        padding-left: 70px;
        padding-right: 70px;
      }
      @media ${screen.small} {
        padding-left: 0;
        padding-right: 0;
        margin-top: 140px;
      }
      @media ${screen.xlarge} {
        margin-top: 180px;
      }
    }
  }
`;

const TrainingPage = ({ data }) => {
  const {
    introduction,
    description,
    video_url,
    video_label,
    safety_training_list,
    title_tag,
    meta_description,
  } = data.main.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="inner-wrapper">
          <div className="content">
            <div className="content__video">
              <div className="video-wrapper">
                <iframe
                  className="video-wrapper__iframe"
                  title="safety-demo"
                  src={`https://player.vimeo.com/video/${getVimeoId(
                    video_url
                  )}`}
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              <div
                className="video-label"
                dangerouslySetInnerHTML={{ __html: video_label.html }}
              />
            </div>
            <div className="content__text">
              <PageTitle className="page-title">
                Safety Training with ATLC
              </PageTitle>
              <p className="service-intro">{introduction}</p>
              <div
                className="service-description"
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
            </div>
          </div>

          <section className="training">
            <h2 className="training__heading">
              The ATLC Safety Training is trusted by
            </h2>
            <ul className="training__list">
              {safety_training_list.map((item, i) => (
                <li className="training-item" key={i}>
                  <a
                    className="training-item__logo"
                    href={item.website_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Visit Safety Training Website"
                  >
                    <GatsbyImage
                      image={item.logo.gatsbyImageData}
                      alt={item.logo.alt || "Brand"}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </section>

          <section className="contact">
            <GetInTouch
              heading={`Want to know more?`}
              subheading={`Get in touch with our team, we'd love to help.`}
              linkLabel={`SAY HELLO`}
            />
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default TrainingPage;

export const dataQuery = graphql`
  {
    main: prismicServicePage {
      data {
        introduction
        description {
          html
        }
        video_url
        video_label {
          html
        }
        safety_training_list {
          logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          website_url
        }
        title_tag
        meta_description
      }
    }
  }
`;
